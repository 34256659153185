<template>
  <div class="socialMedia">
    <div class="brand" v-for="(media, key, index) in socialMedia" :key="'social-' + key + index">
      <a :href="media.link" target="_blank">
        <svg-icon :iconClass="media.img"></svg-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMedia',
  data() {
    return {
      socialMedia: [
        { name: 'Facebook', link: 'https://www.facebook.com/startrader.intl/', img: 'facebook' },
        { name: 'Linked In', link: 'https://www.linkedin.com/company/startrader-global/', img: 'linked_in' },
        { name: 'Twitter', link: 'https://twitter.com/STARTRADER_int', img: 'twitter' },
        {
          name: 'Instagram',
          link: 'https://www.instagram.com/startrader.international/',
          img: 'instagram',
        },
        {
          name: 'Youtube',
          link: 'https://www.youtube.com/@Startrader.international/featured',
          img: 'youtube',
        },
        { name: 'Tiktok', link: 'https://www.tiktok.com/@startraderofficial', img: 'tiktok' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.socialMedia {
  display: flex;

  .svg-icon {
    font-size: 24px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:hover {
      color: $primary;
    }
  }
}
</style>
