<template>
  <PageWrapper>
    <PagePanel>
      <p class="contact_desc">{{ $t('contact.desc', { platform: $config.info.fullName }) }}</p>
      <div class="contact_box">
        <div class="contact_l_item">
          <img src="@/assets/images-1/contact/chat.png" class="item_icon" />
          <div class="content">
            <a @click="showLiveChat" data-testid="showLiveChat">{{ $t('contact.click') }}</a>
            {{ $t('contact.assist') }}
          </div>
        </div>
        <div class="contact_l_item">
          <img src="@/assets/images-1/contact/email.png" class="item_icon" />
          <div class="content">
            <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
          </div>
        </div>
        <div class="contact_l_item social_box">
          <SocialMedia></SocialMedia>
        </div>
      </div>
      <div class="map_box">
        <a :href="`https://${GLOBAL_DOMAIN_WEBSITE}`">
          <img src="@/assets/images-1/contact/map.png" alt="" />
        </a>
      </div>
    </PagePanel>
  </PageWrapper>
</template>

<script>
import SocialMedia from '@/components/SocialMedia'

export default {
  components: { SocialMedia },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/PUGContactUs.scss';
.contact_desc {
  font-size: 14px;
  color: $text-secondary;
  padding-bottom: 16px;
}

.contact_box {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: $background-color-base;
  border-radius: 8px;
  margin-bottom: 16px;

  @include screen-mobile {
    flex-direction: column;
    padding: 16px;
  }

  .contact_l_item {
    display: flex;
    align-items: center;
    width: 33.3%;

    @include screen-mobile {
      width: 100%;
      margin-bottom: 16px;
    }

    &.social_box {
      justify-content: flex-end;

      @include screen-mobile {
        justify-content: flex-start;
        margin-bottom: 0px;
      }
    }

    .item_icon {
      width: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 8px);
    }

    .content {
      color: $secondary;

      a {
        text-decoration: underline;
      }
    }
  }
}

.map_box {
  background-color: $background-color-base;
  border-radius: 8px;
}
</style>
